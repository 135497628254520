export const BadgeLevel = ({
  level,
  label = "",
}: {
  level: number
  label?: string
}): JSX.Element => {
  const defaultColor = {
    text: "text-gray-800",
    bg: "bg-gray-100",
    border: "border-gray-800",
  }

  const colors = {
    1: {
      text: "text-yellow-950",
      bg: "bg-yellow-300",
      border: "border-yellow-300",
    },
    2: {
      text: "text-orange-50",
      bg: "bg-orange-500",
      border: "border-orange-500",
    },
    3: {
      text: "text-red-50",
      bg: "bg-red-500",
      border: "border-red-500",
    },
    4: {
      text: "text-purple-50",
      bg: "bg-purple-700",
      border: "border-purple-700",
    },
  }

  const color = colors[level] || defaultColor

  return (
    <span
      className={`inline-flex px-2 text-xs font-bold leading-5 rounded-full border ${color.border} ${color.text} ${color.bg}`}
      title="Échelle allant de 1 (la moins grave) à 4 (la plus grave) pour les personnes ou 3 pour les biens."
    >
      {`${label ? label + " " : ""}${level}`}
    </span>
  )
}
